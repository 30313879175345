<script lang="ts" setup>import { toRef as _toRef, computed as _computed } from 'vue';

import { useSharedData, usePage } from '@consumer/composables/sharedData'
import searchIcon from '@consumer/assets/images/search-icon.webp'
import { checkout } from '@consumer/api'
import TopNavDropdown from './TopNavDropdown.vue'
import TopNavCategories from './TopNavCategories.vue'

const __$temp_1 = (usePage()),
  url = _toRef(__$temp_1, 'url');
const isCheckout = _computed(() => url.value.startsWith(checkout.create.path()))

const __$temp_2 = (useSharedData()),
  activeHoliday = _toRef(__$temp_2, 'activeHoliday'),
  currentUser = _toRef(__$temp_2, 'currentUser');

const searchPath = '/gift-cards'
</script>

<template>
  <ul class="hidden lg:flex text-16px h-full lg:flex-row gap-4 items-center">
    <template v-if="currentUser?.corporate && !currentUser?.giftlyEmployee">
      <li class="px-12px">
        <GButton
          name="Corporate Portal"
          href="/corp/dashboard"
          primary
          size="large"
          label="Go To Corporate Portal"
          class="!px-32px !py-14px !h-50px"
        />
      </li>
    </template>
    <template v-else-if="isCheckout">
      <li><HowItWorksLink class="top-nav-link" trackNavLinkClick/></li>
    </template>
    <template v-else>
      <li><TopNavDropdown label="Categories"><TopNavCategories/></TopNavDropdown></li>
      <li><GLink class="top-nav-link" href="/browse" data-track-ga="top-nav-link">Browse</GLink></li>
      <li><HowItWorksLink class="top-nav-link" trackNavLinkClick/></li>
      <li v-if="activeHoliday === 'holiday'">
        <GLink class="top-nav-link" href="/browse/christmas-gifts" data-track-ga="top-nav-link">
          Holiday
        </GLink>
      </li>
      <li v-if="activeHoliday === 'mothers_day'">
        <GLink class="top-nav-link" href="/browse/mothers-day-gifts" data-track-ga="top-nav-link">
          Mother's Day
        </GLink>
      </li>
      <li v-if="activeHoliday === 'fathers_day'">
        <GLink class="top-nav-link" href="/browse/fathers-day-gifts" data-track-ga="top-nav-link">
          Father's Day
        </GLink>
      </li>
      <li v-if="!url.startsWith(searchPath)" class="px-12px flex items-center justify-center">
        <a :href="searchPath" data-track-ga="top-nav-link">
          <img
            :src="searchIcon"
            alt="Search"
            width="19"
            height="17"
            class="transform duration-300 ease-in-out hover:scale-130"
          >
        </a>
      </li>
    </template>
  </ul>
</template>
