<script lang="ts" setup>import { ref as _ref } from 'vue';

import { useSharedData } from '@consumer/composables/sharedData'
import { compact } from 'lodash-es'
import { removeBy } from '@corp/helpers/array'

const props = useSharedData()

type Message = { message: string; type: 'error' | 'info' }
let messages = _ref<Message[]>([])

watch(() => props.flash, () => {
  const { alert, notice } = props.flash
  if (alert || notice) {
    messages.value = compact([
      alert && { message: alert, type: 'error' },
      notice && { message: notice, type: 'info' },
    ])
    // Ensure same message is detected as change and displayed each time.
    props.flash = {}
  }
}, { immediate: true })
</script>

<template>
  <div v-if="messages.length" class="flex flex-col bg-white">
    <template v-for="flashMessage in messages" :key="flashMessage.type">
      <GMessage
        class="max-w-60ch mx-auto my-3 whitespace-pre-wrap"
        :type="flashMessage.type"
        @afterLeave="removeBy(messages, (m) => m === flashMessage)"
      >{{ flashMessage.message }}</GMessage>
    </template>
  </div>
</template>
