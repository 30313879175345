<script lang="ts" setup>import { toRef as _toRef, computed as _computed } from 'vue';

import topNavLogoBgMothersDay from '@consumer/assets/images/topnav-logo-bg-mothers-day.svg'
import topNavLogoBgFathersDay from '@consumer/assets/images/topnav-logo-bg-fathers-day.svg'
import giftlyLogoDefault from '@consumer/assets/images/giftly-logo.svg'
import { useSharedData } from '@consumer/composables/sharedData'
import { isDesktop } from '@consumer/utils/breakpoints'

const __$temp_1 = (useSharedData()),
  activeHoliday = _toRef(__$temp_1, 'activeHoliday');

const topNavLogoBgMothersDayUrl = _computed(() => `url(${topNavLogoBgMothersDay})`)
const topNavLogoBgFathersDayUrl = _computed(() => `url(${topNavLogoBgFathersDay})`)
</script>

<template>
  <div
    class="giftly-logo w-260px bg-contain flex items-center h-full"
    :class="`giftly-logo__${activeHoliday}`"
  >
    <GLink href="/">
      <img
        :width="isDesktop ? 85 : 65"
        height="40"
        :src="giftlyLogoDefault"
        alt="Giftly"
      >
    </GLink>
  </div>
</template>

<style lang="scss" scoped>
.giftly-logo {
  &__holiday {
    @media only screen and (min-width: 1024px) {
      background-position: 20px center;
    }

    @apply bg-no-repeat bg-contain;

    background-image: url("https://assets.giftly.com/assets/responsive/header-holiday-new-nav-733a8ed001ae36757fc60462df3d36b913e1bd3d6012526f93117d71a1c6e4fd.png");
    background-position: 4px center;
  }

  &__mothers_day {
    @media only screen and (min-width: 1024px) {
      @apply bg-no-repeat bg-contain pl-60px;

      background-image: v-bind("topNavLogoBgMothersDayUrl");
      background-size: 56px 56px;
    }
  }

  &__fathers_day {
    @media only screen and (min-width: 1024px) {
      @apply bg-no-repeat bg-contain pl-60px;

      background-image: v-bind("topNavLogoBgFathersDayUrl");
      background-size: 56px 56px;
    }
  }
}
</style>
