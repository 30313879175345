export { compact, groupBy, sortBy, union, uniq } from 'lodash-es'

// Public: Removes an element from the Array using 'splice`, which Vue's
// reactive system can detect.
//
// Returns the removed item, or undefined.
export function removeBy<T> (array: T[], itemOrPredicate: (item: T) => any): T | undefined {
  const index = array.findIndex(itemOrPredicate)
  if (index !== -1) return array.splice(index, 1)[0]
}

// Public: Equivalent to Array.wrap in Ruby, wraps a single object.
export function wrapArray<T> (val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val]
}

// Public: Returns the first non-empty result of evaluating the predicate.
export function mapFind<T> (array: T[], predicate: (item: T) => any, { hasFound = Boolean } = {}) {
  for (let i = 0; i < array.length; i++) {
    const result = predicate(array[i])
    if (hasFound(result)) return result
  }
}
