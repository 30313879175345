<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import TopSubNavUser from '@consumer/modules/layout/TopSubNavUser.vue'
import { useSharedData } from '@consumer/composables/sharedData'

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');
</script>

<template>
  <div
    v-if="!currentUser?.corporate || currentUser?.giftlyEmployee"
    class="hidden lg:block h-34px top-sub-nav relative text-navy bg-[#eee]"
  >
    <GContainer class="h-full text-14px flex justify-end items-center gap-30px !pr-20px relative">
      <GLink href="/entercode" alt="Redeem" data-track-ga="top-nav-link">Redeem</GLink>
      <GLink href="/corp" alt="Corporate" data-track-ga="top-nav-link">Corporate</GLink>
      <GLink href="/support" alt="Help" data-track-ga="top-nav-link">Help</GLink>

      <TopSubNavUser v-if="currentUser" :user="currentUser"/>
      <template v-else>
        <GLink href="/login" alt="Login" data-track-ga="top-nav-link">Login</GLink>
      </template>
    </GContainer>
  </div>
</template>

<style lang="scss" scoped>
a {
  color: #111e36 !important;
  text-decoration: none;
}
</style>
