<script setup lang="ts">
import ProfileForm from '@consumer/modules/myAccount/ProfileForm.vue'
import { openErrorAlertModal } from '@consumer/services/modals'

const isOpen = ref(true)

const onClose = () => {
  isOpen.value = false
}

const onSubmitSuccess = () => isOpen.value = false

const onSubmitFailed = (error: string) => {
  const content = [
    h('p', { class: 'p-3' }, error),
  ]
  openErrorAlertModal({
    name: 'purchase-request-error-alert-modal',
    header: 'Oops, something went wrong',
    content,
  })
}
</script>

<template>
  <GModal
    id="update-profile-modal"
    name="update-profile-modal"
    :open="isOpen"
    class="w-screen-lg p-8 pt-10 rounded"
    @close="onClose"
  >
    <h1 class="font-semibold text-2xl mb-4">Please Update Your Information</h1>
    <p class="!mb-0">We need your birthday and phone number to keep your account up-to-date. </p>
    <div class="py-6">
      <GDivider/>
    </div>

    <ProfileForm
      @submit:success="onSubmitSuccess"
      @submit:failed="onSubmitFailed"
    />
  </GModal>
</template>
