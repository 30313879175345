<script setup lang="ts">
import GInputField from '@consumer/components/GInputField.vue'
import { useModelWrapper } from '@consumer/composables/modelWrapper'
import { phoneNumber as phoneNumberValidator, useValidation, withMessage } from '@consumer/composables/validation'
import { formatDate } from '@consumer/utils/date'
import { required } from '@vuelidate/validators'

const props = defineProps<{
  firstName: string
  lastName: string
  birthday: string
  phoneNumber: string
}>()

interface Emit {
  (e: 'update:firstName', value: string): void
  (e: 'update:lastName', value: string): void
  (e: 'update:birthday', value: string): void
  (e: 'update:phoneNumber', value: string): void
}
const emit = defineEmits<Emit>()

const firstName = useModelWrapper(props.firstName, emit, 'firstName')
const lastName = useModelWrapper(props.lastName, emit, 'lastName')
const birthday = useModelWrapper(props.birthday, emit, 'birthday')
const phoneNumber = useModelWrapper(props.phoneNumber, emit, 'phoneNumber')

const firstNameField = ref<InstanceType<typeof GInputField>>()
const lastNameField = ref<InstanceType<typeof GInputField>>()
const birthdayField = ref<InstanceType<typeof GInputField>>()
const phoneNumberField = ref<InstanceType<typeof GInputField>>()

const validation = useValidation({
  firstName: {
    required: withMessage('Please enter your first name', required),
  },
  lastName: {
    required: withMessage('Please enter your last name', required),
  },
  birthday: {
    required: withMessage('Please enter your date of birth', required),
  },
  phoneNumber: {
    phoneNumber: withMessage('Please enter a valid US phone number', phoneNumberValidator),
  },
}, {
  phoneNumber,
  firstName,
  lastName,
  birthday,
})

const activate = () => {
  [
    firstNameField.value,
    lastNameField.value,
    birthdayField.value,
    phoneNumberField.value,
  ].forEach(field => field?.activate())
}

defineExpose({
  activate,
})
</script>

<template>
  <div>
    <GInputField
      ref="firstNameField"
      v-model="firstName"
      label="First Name"
      placeholder="Enter Your First Name"
      class="w-full"
      :error="validation.firstName.$errors"
      :errorTooltip="true"
    />
  </div>
  <div>
    <GInputField
      ref="lastNameField"
      v-model="lastName"
      label="Last Name"
      placeholder="Enter Your Last Name"
      class="w-full"
      :error="validation.lastName.$errors"
      :errorTooltip="true"
    />
  </div>
  <div>
    <GInputField
      ref="birthdayField"
      v-model="birthday"
      label="Birthday"
      type="date"
      :max="formatDate(new Date(), { format: 'yyyy-MM-dd'})"
      class="w-full min-w-full appearance-none"
      :error="validation.birthday.$errors"
      :errorTooltip="true"
    />
  </div>
  <div>
    <GInputField
      ref="phoneNumberField"
      v-model="phoneNumber"
      v-mask="'(###) ###-####'"
      label="Phone Number"
      class="w-full"
      placeholder="ex: (555) 444-4444"
      :error="validation.phoneNumber.$errors"
      :errorTooltip="true"
    />
  </div>
</template>
