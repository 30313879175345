<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useSharedData } from '@consumer/composables/sharedData'
import { useValidation } from '@consumer/composables/validation'
import ProfileFields from '@consumer/modules/signup/ProfileFields.vue'
import { myAccount } from '@consumer/api'

const profileFields = ref<InstanceType<typeof ProfileFields>>()

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');

const form = reactive({
  firstName: '',
  lastName: '',
  birthday: '',
  phoneNumber: '',
})

const validation = useValidation({}, form)

const isSubmitEnabled = ref(false)
const isSubmitting = ref(false)

watch(
  () => form,
  () => {
    if (Object.values(form).every(value => !!value)) {
      validation.value.$validate().then((value) => {
        isSubmitEnabled.value = value
      })
    }
  },
  { deep: true },
)

interface Emit {
  (e: 'submit'): void
  (e: 'submit:success'): void
  (e: 'submit:failed', error: string): void
}
const emit = defineEmits<Emit>()

const onSubmit = () => {
  emit('submit')
  isSubmitting.value = true

  profileFields.value?.activate()

  validation.value.$validate().then((valid) => {
    if (valid) {
      myAccount.updateInfo({ data: toRaw(form) })
        .then(() => {
          emit('submit:success')
          isSubmitting.value = false
        })
        .catch((error) => {
          emit('submit:failed', error.message)
          isSubmitting.value = false
        })
    }
    else {
      isSubmitEnabled.value = false
      isSubmitting.value = false
    }
  }).catch((error) => {
    emit('submit:failed', error.message)
    isSubmitEnabled.value = false
    isSubmitting.value = false
  })
}

watchEffect(() => {
  if (form && currentUser.value) {
    form.firstName = currentUser.value.firstName ?? ''
    form.lastName = currentUser.value.lastName ?? ''
    form.birthday = currentUser.value.birthday?.toString() ?? ''
    form.phoneNumber = currentUser.value.phoneNumber ?? ''
  }
})
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-6">
      <ProfileFields
        ref="profileFields"
        v-model:firstName="form.firstName"
        v-model:lastName="form.lastName"
        v-model:birthday="form.birthday"
        v-model:phoneNumber="form.phoneNumber"
      />
    </div>

    <div class="flex flex-col gap-10 lg:flex-row justify-end !mt-10">
      <GButton
        primary
        size="large"
        class="w-full lg:w-188px"
        name="save-update-profile-button"
        type="submit"
        label="Save Changes"
        :class="!isSubmitEnabled ? 'disabled cursor-help' : ''"
        :processing="isSubmitting"
      />
    </div>
  </form>
</template>
